import { combineReducers } from "redux";
// import { DESTROY_SESSION } from "../actions";
//
import auth from "./auth";
import jobs from "./jobs";
import users from "./users";
import admin from "./admin";
import workers from "./workers";
import toasts from "./toasts";
import stats from "./stats";
import loading from "./loading";

const appReducer = combineReducers({
  auth,
  jobs,
  users,
  admin,
  workers,
  toasts,
  stats,
  loading,
});

const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
