import backend from "../utilts/backendAPI";

export const fetchUserJobs =
  ({ limit, page, accountId }) =>
  async (dispatch) => {
    try {
      const response = await backend.GET(
        `/v2/api/hello/accounts/${accountId}/jobs`,
        { limit, page }
      );
      dispatch({ type: "FETCH_USER_JOBS", payload: response });
    } catch (error) {
      return error;
    }
  };

export const resetUserJobs = () => {
  return { type: "RESET_USER_JOBS" };
};

export const setUsersInprogressJobs = (data) => {
  return { type: "SET_USERS_INPROGRESS_JOBS", payload: data };
};

export const fetchUserStats =
  ({ formData, accountId }) =>
  async (dispatch) => {
    console.log("{ formData, accountId }", { formData, accountId });
    try {
      const response = await backend.GET(
        `/v2/api/hello/accounts/${accountId}/cost`,
        formData
      );

      dispatch({ type: "FETCH_USER_STATS", payload: response });
    } catch (error) {
      return error;
    }
  };

export const resetUserStats = () => {
  return { type: "RESET_USER_STATS" };
};
