import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { createJob } from "../../actions/jobs";
import { classNames } from "primereact/utils";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import options from "./options";

import styles from "./index.module.scss";

const validationSchema = yup.object({
  videoUrl: yup.string().required("video Url is required"),
});

const TranscoderForm = ({ onHide }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      videoUrl:
        // "https://drive.google.com/uc?export=download&id=1DVbfrUpVPG3Q0u6O8lnRuHC00PR-QvAt",
        "https://transcoder-video-demo.meshstream.io/video.mp4",
      job_type: "transcode",
      paramPreset: options.presetOptions[0],
      paramBitrate: options.bitrateOptions[0],
      paramCrf: options.crfOptions[0],
      resolution: options.resolutionOptions[0],
      meshubNumbers: options.numberOptions[0],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formikData = JSON.parse(JSON.stringify(values));

      const formatedFormData = {
        transcode_job: {
          sourceUrl: values.videoUrl,
          meshubNumbers: values.meshubNumbers.meshubNumbers,
          job_type: "transcode", // (前端先寫死)
        },
        resolutions: [
          {
            paramBitrate: values.paramBitrate.paramBitrate,
            paramCrf: values.paramCrf.paramCrf,
            paramResolutionWidth:
              values.resolution.resolution.paramResolutionWidth,
            paramResolutionHeight:
              values.resolution.resolution.paramResolutionHeight,
            paramPreset: values.paramPreset.paramPreset,
            resolution: `${values.resolution.resolution.paramResolutionHeight}P`,
          },
        ],
      };

      dispatch(createJob(formatedFormData));
      onHide();
      formik.handleReset();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div className={styles.transcoderForm}>
      <header>
        <h1>Transcoding</h1>
      </header>
      <main className={styles.main}>
        <form
          autoComplete="off"
          className="p-fluid"
          onSubmit={formik.handleSubmit}
        >
          <div className="p-field p-mb-5 p-mt-5">
            <span className="p-float-label">
              <InputText
                id="videoUrl"
                name="videoUrl"
                value={formik.values.videoUrl}
                onChange={formik.handleChange}
              />
              <label
                htmlFor="videoUrl"
                className={classNames({
                  "p-error": isFormFieldValid("videoUrl"),
                })}
              >
                Video Url
              </label>
            </span>
            {getFormErrorMessage("videoUrl")}
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <Dropdown
                id="paramBitrate"
                name="paramBitrate"
                value={formik.values.paramBitrate}
                onChange={formik.handleChange}
                options={options.bitrateOptions}
                optionLabel="label"
              />
              <label htmlFor="paramBitrate">Bitrate</label>
            </span>
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <Dropdown
                id="paramCrf"
                name="paramCrf"
                value={formik.values.paramCrf}
                onChange={formik.handleChange}
                options={options.crfOptions}
                optionLabel="label"
              />
              <label htmlFor="paramCrf">Crf</label>
            </span>
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <Dropdown
                id="resolution"
                name="resolution"
                value={formik.values.resolution}
                onChange={formik.handleChange}
                options={options.resolutionOptions}
                optionLabel="label"
              />
              <label htmlFor="resolution">Resolution</label>
            </span>
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <Dropdown
                id="meshubNumbers"
                name="meshubNumbers"
                value={formik.values.meshubNumbers}
                onChange={formik.handleChange}
                options={options.numberOptions}
                optionLabel="label"
              />
              <label htmlFor="meshubNumbers">Numbers</label>
            </span>
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <Dropdown
                id="paramPreset"
                name="paramPreset"
                value={formik.values.paramPreset}
                onChange={formik.handleChange}
                options={options.presetOptions}
                optionLabel="label"
              />
              <label htmlFor="paramPreset">Preset</label>
            </span>
          </div>
          <Button
            label="Add"
            className="p-button-help p-button-rounded"
            type="submit"
          />
        </form>
      </main>
    </div>
  );
};

export default TranscoderForm;
