import backend from "../utilts/backendAPI";

export const fetchMyStats = (data) => async (dispatch) => {
  console.log("data", data);
  try {
    const response = await backend.GET(`/v2/api/hello/accounts/cost`, data);
    dispatch({ type: "FETCH_MY_STATS", payload: response });
  } catch (error) {
    return error;
  }
};
