import React from "react";
import { ProgressBar } from "primereact/progressbar";

const CustomProgressBar = ({ value }) => {
  return (
    <ProgressBar
      value={value}
      style={{ height: "15px", fontSize: "12px" }}
    ></ProgressBar>
  );
};

export default CustomProgressBar;
