import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

import "./index.scss";

const LoadingView = () => {
  return (
    <div className="loadingView">
      <BeatLoader color="#0288D1" size={12} />
    </div>
  );
};

export default LoadingView;
