import backend from "../utilts/backendAPI";
import Cookies from "js-cookie";
import history from "../history";

import { addToast } from "./toasts";

export const setToken = (token) => {
  return { type: "SET_TOKEN", payload: { token } };
};

export const login = (data) => async (dispatch) => {
  try {
    const { token, isAdmin, message } = await backend.POST(
      "/v2/api/account/login",
      data
    );
    dispatch({ type: "LOGIN", payload: { token, isAdmin } });
    Cookies.set("transcoder", JSON.stringify({ token, isAdmin }), {
      secure: true,
      sameSite: "strict",
      expires: 7,
    });
    history.push("/");
    dispatch(
      addToast({
        severity: "info",
        content: message,
        closable: false,
        life: 3000,
      })
    );
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: 'Wrong username or password"',
        closable: false,
        life: 3000,
      })
    );
  }
};

export const logout = () => (dispatch) => {
  Cookies.remove("transcoder");
  dispatch({ type: "LOGOUT" });
  window.location.reload();
};
