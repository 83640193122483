import axios from "axios";
import store from "../store";
import { logout } from "../actions/auth";

const backend = axios.create({
  baseURL: "https://transcoder-api.meshstream.io",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

backend.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;

    if (token) {
      config.headers["X-MESHUB-TRANSCODER-API-TOKEN"] = token;
      config.headers.Authorization = `Bearer ${token}`;
    } else {
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.status === 401) {
      const { dispatch } = store;
      dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default {
  async GET(url, params) {
    try {
      const response = await backend.get(url, {
        params,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.data);
    }
  },
  async POST(url, body, config) {
    try {
      const response = await backend.post(url, body, config);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async DEL(url, body, config) {
    try {
      const response = await backend.delete(url, body, config);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async PUT(url, body, config) {
    try {
      const response = await backend.put(url, body, config);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};
