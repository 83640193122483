import React from "react";
import meshLogo from "./images/meshLogo.png";

import styles from "./index.module.scss";

const TopNav = () => {
  return (
    <div className={styles.topNav}>
      <div className={styles.logo}>
        <img src={meshLogo} alt="meshub" />
      </div>
    </div>
  );
};

export default TopNav;
