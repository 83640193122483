import backend from "../utilts/backendAPI";
import { addToast } from "./toasts";

export const getUsers = (data) => async (dispatch) => {
  try {
    const response = await backend.GET("/v2/api/account", data);
    dispatch({ type: "GET_USERS", payload: response });
  } catch (error) {
    return error;
  }
};

export const createUser = (data) => async (dispatch) => {
  try {
    const { account, message } = await backend.POST("/v2/api/account", data);
    dispatch({ type: "CREATE_USER", payload: account });
    dispatch(
      addToast({
        severity: "success",
        content: message,
        closable: false,
        life: 3000,
      })
    );
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "Error",
        closable: false,
        life: 3000,
      })
    );
  }
};

export const deleteUser = (accountId) => async (dispatch) => {
  try {
    await backend.DEL(`/v2/api/account/${accountId}`);
    dispatch({ type: "DELETE_USER", payload: { accountId } });
    dispatch(
      addToast({
        severity: "success",
        content: "Delete user successfully",
        closable: false,
        life: 3000,
      })
    );
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "Error",
        closable: false,
        life: 3000,
      })
    );
  }
};

export const resetUserPassword =
  ({ formData, accountId }) =>
  async (dispatch) => {
    try {
      await backend.PUT(
        `/v2/api/account/reset-password/${accountId}`,
        formData
      );

      dispatch(
        addToast({
          severity: "success",
          content: "Reset password successfully",
          closable: false,
          life: 3000,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          severity: "error",
          content: "Error",
          closable: false,
          life: 3000,
        })
      );
    }
  };
