import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { resetUserPassword } from "../../actions/users";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

const EditUserForm = ({ rowData }) => {
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      const formikData = JSON.parse(JSON.stringify(data));

      dispatch(
        resetUserPassword({ formData: formikData, accountId: rowData._id })
      );
      setDisplayDialog(false);
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onHide = () => {
    setDisplayDialog(false);
    formik.resetForm();
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  const Footer = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
      <Button
        label="Edit"
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.handleSubmit}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={onOpen}
      />
      <Dialog
        header="Edit User"
        footer={Footer}
        visible={displayDialog}
        modal
        onHide={onHide}
      >
        <div className="p-fluid">
          <div className="p-field">
            <span className="p-float-label">
              <InputText
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                // toggleMask
                className={classNames({
                  "p-invalid": isFormFieldValid("password"),
                })}
              />
              <label
                htmlFor="password"
                className={classNames({
                  "p-error": isFormFieldValid("password"),
                })}
              >
                New Password
              </label>
            </span>
            {getFormErrorMessage("password")}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditUserForm;
