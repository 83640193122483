import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkers } from "../../actions/workers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SplitJobsTable from "../SplitJobsTable";
import CustomPaginator from "../CustomPaginator";
import LoadingView from "../LoadingView";

const WorkersTable = () => {
  const dispatch = useDispatch();
  const { workers } = useSelector((state) => state);

  useEffect(() => {
    dispatch(fetchWorkers());
  }, []);

  const jobsBodyTemplate = (rowData) => {
    return <SplitJobsTable rowData={rowData} />;
  };

  if (workers.workers.data === null) return <LoadingView />;
  return (
    <React.Fragment>
      <DataTable
        value={workers.workers.data.docs}
        className="p-datatable-sm"
        rowHover
        autoLayout
        selectionMode="single"
      >
        {workers.workers.fields.map((item, index) => {
          return (
            <Column
              key={index}
              header={item.displayField}
              field={item.dataField}
              sortable
              filter
              filterMatchMode="contains"
            />
          );
        })}
        <Column header="splitjobs" body={jobsBodyTemplate} />
      </DataTable>
      <CustomPaginator
        totalRecords={workers.workers.data.totalDocs}
        onPageChange={({ page, limit }) =>
          dispatch(fetchWorkers({ page, limit }))
        }
      />
    </React.Fragment>
  );
};

export default WorkersTable;
