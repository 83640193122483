import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { fetchMyJobs, removeMp4, deletePendingJobs } from "../../actions/jobs";
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import CustomProgressBar from "../CustomProgressBar";
import CustomPaginator from "../CustomPaginator";
import CreateJobForm from "../CreateJobForm";
import styles from "./index.module.scss";

const formatTime = (time) => {
  return dayjs(time).format("MM/DD HH:mm:ss");
};

const MyJobsTable = ({ jobs, isOnlyShowInprogress }) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectedPendingJobs, setSelectedPendingJobs] = useState({});

  const removeMp4BodyTemplate = (rowData) => {
    if (rowData.mp4_removed === "true") return null;

    return (
      <Button
        label="remove"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={() =>
          dispatch(removeMp4({ uuid: rowData.uuid, _id: rowData._id }))
        }
      />
    );
  };

  const isChecked = (id) => {
    if (selectedPendingJobs[id]) return true;
    else return false;
  };

  const handleCheckboxChange = (rowData) => {
    if (isChecked(rowData._id)) {
      const newSelectedPendingJobs = { ...selectedPendingJobs };
      delete newSelectedPendingJobs[rowData._id];
      setSelectedPendingJobs(() => newSelectedPendingJobs);
    } else {
      setSelectedPendingJobs(() => ({
        ...selectedPendingJobs,
        [rowData._id]: rowData,
      }));
    }
  };

  const deletePendingJobBodyTemplate = (rowData) => {
    if (rowData.status !== "pending" && selectedPendingJobs[rowData._id]) {
      const newSelectedPendingJobs = { ...selectedPendingJobs };
      delete newSelectedPendingJobs[rowData._id];
      setSelectedPendingJobs(() => newSelectedPendingJobs);
    }

    if (rowData.status !== "pending") return null;

    return (
      <React.Fragment>
        <Checkbox
          className="p-mr-2"
          onChange={() => handleCheckboxChange(rowData)}
          checked={isChecked(rowData._id)}
        ></Checkbox>
      </React.Fragment>
    );
  };

  const progressBodyTemplate = (value) => {
    return <CustomProgressBar value={value} />;
  };

  const rowExpansionTemplate = (data) => {
    const timeData = [
      {
        status: "Pending",
        date: formatTime(data.pending_at),
        diff: dayjs(data.transcoding_at).diff(data.pending_at, "second"),
        icon: "pi pi-caret-right",
        color: "#9C27B0",
      },
      {
        status: "Transcoding",
        date: formatTime(data.transcoding_at),
        diff: dayjs(data.uploading_at).diff(data.transcoding_at, "second"),
        icon: "pi pi-cog",
        color: "#673AB7",
      },
      {
        status: "Uploading",
        date: formatTime(data.uploading_at),
        diff: dayjs(data.merging_at).diff(data.uploading_at, "second"),
        icon: "pi pi-cloud-upload",
        color: "#FF9800",
      },
      {
        status: "Merging",
        date: formatTime(data.merging_at),
        diff: dayjs(data.finished_at).diff(data.merging_at, "second"),
        icon: "pi pi-reply",
        color: "#1B61AF",
      },
      {
        status: "Finished",
        date: formatTime(data.finished_at),
        diff: `Total cost: ${dayjs(data.finished_at).diff(
          data.pending_at,
          "second"
        )}`,
        icon: "pi pi-check",
        color: "#607D8B",
      },
    ];

    const customizedMarker = (item) => {
      return (
        <span
          className={`p-shadow-2 ${styles.customMarker}`}
          style={{ backgroundColor: item.color }}
        >
          <i className={item.icon}></i>
        </span>
      );
    };

    const customizedContent = (item) => {
      return <Card title={item.status} subTitle={`${item.diff} s`}></Card>;
    };

    return (
      <React.Fragment>
        <div className={styles.timeline}>
          <Timeline
            value={timeData}
            layout="horizontal"
            align="top"
            content={(item) => item}
            marker={customizedMarker}
            content={customizedContent}
            opposite={(item) => item.date}
          />
        </div>
        <div className="inside-table-title">
          <span>Split Jobs:</span>
        </div>
        <DataTable value={data.splitJobs} showGridlines autoLayout>
          {jobs.myJobs.splitJobFields.map((item, index) => {
            if (item.dataField === "progress") {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) => progressBodyTemplate(rowData.progress)}
                  sortable
                />
              );
            }
            return (
              <Column
                key={index}
                field={item.dataField}
                header={item.displayField}
                sortable
              />
            );
          })}
        </DataTable>
      </React.Fragment>
    );
  };

  const header = (
    <div className={styles.tableHeader}>
      <CreateJobForm />
      <Button
        icon="pi pi-trash"
        label={`Delete ${
          Object.values(selectedPendingJobs).length
        } Pending Jobs`}
        disabled={Object.values(selectedPendingJobs).length === 0}
        className="p-button-outlined  p-button-danger"
        onClick={() => {
          dispatch(
            deletePendingJobs({ jobIds: Object.keys(selectedPendingJobs) })
          );
          setSelectedPendingJobs({});
        }}
      />
    </div>
  );

  return (
    <React.Fragment>
      <DataTable
        autoLayout
        value={
          isOnlyShowInprogress
            ? Object.values(jobs.myInprogressJobs)
            : Object.values(jobs.myJobs.data.docs)
        }
        header={header}
        expandedRows={expandedRows}
        className="p-datatable-sm"
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="_id"
        showGridlines
      >
        <Column expander style={{ width: "3em" }} />
        <Column
          header="delete"
          style={{ width: "3em" }}
          body={deletePendingJobBodyTemplate}
        />
        <Column header="removeMp4" body={removeMp4BodyTemplate} />

        {jobs.myJobs.fields.map((item, index) => {
          if (item.dataField === "overall_progress") {
            return (
              <Column
                key={index}
                field={item.dataField}
                header={item.displayField}
                body={(rowData) =>
                  progressBodyTemplate(rowData.overall_progress)
                }
                sortable
              />
            );
          }
          return (
            <Column
              key={index}
              header={item.displayField}
              field={item.dataField}
              sortable
              filter
              filterMatchMode="contains"
            />
          );
        })}
      </DataTable>
      {isOnlyShowInprogress ? null : (
        <CustomPaginator
          totalRecords={jobs.myJobs.data.totalDocs}
          onPageChange={({ page, limit }) =>
            dispatch(fetchMyJobs({ page, limit }))
          }
        />
      )}
    </React.Fragment>
  );
};

export default MyJobsTable;
