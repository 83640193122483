import React from "react";
import { Card } from "primereact/card";

import MyJobsTabView from "../../components/MyJobsTabView";

import styles from "./index.module.scss";

const MyJobs = () => {
  return (
    <div className={styles.myJobs}>
      <Card title="My Jobs">
        <MyJobsTabView />
      </Card>
    </div>
  );
};

export default MyJobs;
