import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../history";
import items from "./items";
import { logout } from "../../actions/auth";
import { Divider } from "primereact/divider";

import styles from "./index.module.scss";

const SidebarNavItems = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [activatedNavItem, setActivatedNavItem] = useState(items[0].name);

  const handleClick = (item) => {
    history.push(item.path);
    setActivatedNavItem(item.name);
  };

  return (
    <React.Fragment>
      <ul>
        {items.map((item) => {
          if (item.adminOnly) {
            if (auth.isAdmin) {
              return (
                <li
                  className={`${styles.listItem} ${
                    activatedNavItem === item.name ? styles.active : null
                  }`}
                  onClick={() => handleClick(item)}
                >
                  <i className={item.icon} />
                  <span>{item.name}</span>
                </li>
              );
            }

            return null;
          } else {
            return (
              <li
                className={`${styles.listItem} ${
                  activatedNavItem === item.name ? styles.active : null
                }`}
                onClick={() => handleClick(item)}
              >
                <i className={item.icon} />
                <span>{item.name}</span>
              </li>
            );
          }
        })}
      </ul>
      <Divider className="p-m-0" />
      <div className={styles.listItem} onClick={() => dispatch(logout())}>
        <i className="pi pi-sign-out" />
        <span>Logout</span>
      </div>
    </React.Fragment>
  );
};

export default SidebarNavItems;
