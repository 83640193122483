import dayjs from "dayjs";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_MY_STATS":
      const formatedData = payload.data.map((item) => ({
        ...item,
        timestamp: dayjs(item.timestamp).format("MM/DD"),
      }));
      return { ...payload, data: formatedData };

    default: {
      return state;
    }
  }
};
