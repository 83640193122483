import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers } from "../../actions/users";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import SpentTimePopup from "../SpentTimePopup";
import CreateUserForm from "../CreateUserForm";
import EditUserForm from "../EditUserForm";
import UserJobsTable from "../UserJobsTable";
import CustomPaginator from "../CustomPaginator";
import LoadingView from "../LoadingView";
import styles from "./index.module.scss";

const editBodyTemplate = (rowData) => {
  return <EditUserForm rowData={rowData} />;
};

const jobsBodyTemplate = (rowData) => {
  return <UserJobsTable rowData={rowData} />;
};

const spentTimeBodyTemplate = (rowData) => {
  return <SpentTimePopup rowData={rowData} />;
};

const header = (
  <div className={styles.tableHeader}>
    <CreateUserForm />
  </div>
);

const UserList = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const deleteBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-danger"
        onClick={() => dispatch(deleteUser(rowData._id))}
      />
    );
  };

  if (users.data === null || users.isLoading) return <LoadingView />;
  return (
    <React.Fragment>
      <DataTable
        value={Object.values(users.data.docs)}
        className="p-datatable-sm"
        rowHover
        header={header}
        autoLayout
        selectionMode="single"
      >
        {users.fields.map((item, index) => {
          return (
            <Column
              key={index}
              header={item.displayField}
              field={item.dataField}
              sortable
              filter
              filterMatchMode="contains"
            />
          );
        })}
        <Column header="jobs" body={jobsBodyTemplate} />
        <Column header="spentTime" body={spentTimeBodyTemplate} />
        <Column header="edit" body={editBodyTemplate} />
        <Column header="delete" body={deleteBodyTemplate} />
      </DataTable>
      <CustomPaginator
        totalRecords={users.data.totalDocs}
        onPageChange={({ page, limit }) => dispatch(getUsers({ page, limit }))}
      />
    </React.Fragment>
  );
};

export default UserList;
