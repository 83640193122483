import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import TranscoderForm from "../TranscoderForm";

const CreateJobForm = () => {
  const [displayDialog, setDisplayDialog] = useState(false);

  const onHide = () => {
    setDisplayDialog(false);
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  return (
    <div>
      <Button
        label="Add Job"
        className="p-button-outlined p-mr-2"
        onClick={onOpen}
      />
      <Dialog
        header="Add Job"
        visible={displayDialog}
        modal
        onHide={onHide}
        breakpoints={{ "1440px": "50vw", "760px": "80vw", "576px": "100vw" }}
      >
        <TranscoderForm onHide={onHide} />
      </Dialog>
    </div>
  );
};

export default CreateJobForm;
