import React from "react";
import { Card } from "primereact/card";
import WorkersTable from "../../components/WorkersTable";
import styles from "./index.module.scss";

const Workers = () => {
  return (
    <div className={styles.workers}>
      <Card title="Workers">
        <WorkersTable />
      </Card>
    </div>
  );
};

export default Workers;
