import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import styles from "./index.module.scss";

const CustomBarChart = ({ data, title }) => {
  const barFormatter = (value) => {
    return Math.round(value);
  };

  return (
    <div className={styles.customBarChart}>
      <h1 className={styles.chart_title}>{title}</h1>
      <div className={styles.container}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width="500px"
            height="300px"
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis
              dataKey="timestamp"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              width={25}
              height={100}
              label={{ value: "Sec", position: "insideTop", dy: -35 }}
            />
            <Tooltip
              formatter={(value, name, props) => {
                return `${value} sec`;
              }}
            />
            <Legend verticalAlign="top" height={36} />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="totalSpentTime" fill="#2196F3">
              <LabelList
                dataKey="totalSpentTime"
                position="top"
                formatter={barFormatter}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CustomBarChart;
