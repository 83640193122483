const initialState = {
  myInprogressJobs: {},
  myJobs: { fields: [], data: { docs: {} } },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_MY_JOBS": {
      const objData = payload.data.docs.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});

      return {
        ...state,
        myJobs: { ...payload, data: { ...payload.data, docs: objData } },
      };
    }

    case "REMOVE_MP4": {
      return {
        ...state,
        myJobs: {
          ...state.myJobs,
          data: {
            ...state.myJobs.data,
            docs: {
              ...state.myJobs.data.docs,
              [payload._id]: {
                ...state.myJobs.data.docs[payload._id],
                mp4_removed: "true",
              },
            },
          },
        },
      };
    }

    case "SET_MY_INPROGRESS_JOBS": {
      return {
        ...state,
        myInprogressJobs: { ...state.myInprogressJobs, [payload._id]: payload },
        myJobs: {
          ...state.myJobs,
          data: {
            ...state.myJobs.data,
            docs: { ...state.myJobs.data.docs, [payload._id]: payload },
          },
        },
      };
    }

    case "REMOVE_MY_INPROGRESS_JOBS": {
      const newInprogressJobs = { ...state.myInprogressJobs };
      delete newInprogressJobs[payload._id];

      return { ...state, myInprogressJobs: newInprogressJobs };
    }

    case "DELETE_PENDING_JOBS": {
      const newInprogressJobs = { ...state.myInprogressJobs };
      const newDocs = { ...state.myJobs.data.docs };

      payload.jobIds.map((id) => {
        if (newInprogressJobs[id]) {
          delete newInprogressJobs[id];
        }

        if (newDocs[id]) {
          delete newDocs[id];
        }
      });

      return {
        ...state,
        myInprogressJobs: newInprogressJobs,
        myJobs: {
          ...state.myJobs,
          data: { ...state.myJobs.data, docs: newDocs },
        },
      };
    }

    default: {
      return state;
    }
  }
};
