import React from "react";
import { useDispatch } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";

import styles from "./index.module.scss";

const LoginForm = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.account) {
        errors.account = "Account is required.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      const formikData = JSON.parse(JSON.stringify(data));

      dispatch(login(formikData));
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div className={styles.login}>
      <h2 className={styles.title}>Meshub Transcoder</h2>
      <div className={styles.formContainer}>
        <h1 className="p-text-center">Login</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="p-fluid"
          autoComplete="off"
          method="post"
          action="/post/"
        >
          <div className="p-field p-mb-6">
            <span className="p-float-label">
              <InputText
                id="account"
                name="account"
                value={formik.values.account}
                onChange={formik.handleChange}
                autoFocus
                autoComplete="off"
                className={classNames({
                  "p-invalid": isFormFieldValid("account"),
                })}
              />
              <label
                htmlFor="account"
                className={classNames({
                  "p-error": isFormFieldValid("account"),
                })}
              >
                Account
              </label>
            </span>
            {getFormErrorMessage("account")}
          </div>
          <div className="p-field p-mb-5">
            <span className="p-float-label">
              <InputText
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                type="text"
                autoComplete="off"
                feedback={false}
                className={classNames({
                  "p-invalid": isFormFieldValid("password"),
                })}
              />
              <label
                htmlFor="password"
                className={classNames({
                  "p-error": isFormFieldValid("password"),
                })}
              >
                Password
              </label>
            </span>
            {getFormErrorMessage("password")}
          </div>
          <Divider />
          <Button type="submit" label="Submit" className="p-mt-2" />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
