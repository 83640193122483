import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { fetchUserJobs, resetUserJobs } from "../../actions/admin";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

import { Timeline } from "primereact/timeline";

import CustomProgressBar from "../CustomProgressBar";
import CustomPaginator from "../CustomPaginator";

import styles from "./index.module.scss";

const formatTime = (time) => {
  return dayjs(time).format("MM/DD HH:mm:ss");
};

const UserJobsTable = ({ rowData }) => {
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const { admin } = useSelector((state) => state);

  const onHide = () => {
    setDisplayDialog(false);
    dispatch(resetUserJobs());
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  const fetchData = () => {
    dispatch(fetchUserJobs({ accountId: rowData._id }));
    onOpen();
  };

  const progressBodyTemplate = (value) => {
    return <CustomProgressBar value={value} />;
  };

  const rowExpansionTemplate = (data) => {
    const timeData = [
      {
        status: "Pending",
        date: formatTime(data.pending_at),
        diff: dayjs(data.transcoding_at).diff(data.pending_at, "second"),
        icon: "pi pi-caret-right",
        color: "#9C27B0",
      },
      {
        status: "Transcoding",
        date: formatTime(data.transcoding_at),
        diff: dayjs(data.uploading_at).diff(data.transcoding_at, "second"),
        icon: "pi pi-cog",
        color: "#673AB7",
      },
      {
        status: "Uploading",
        date: formatTime(data.uploading_at),
        diff: dayjs(data.merging_at).diff(data.uploading_at, "second"),
        icon: "pi pi-cloud-upload",
        color: "#FF9800",
      },
      {
        status: "Merging",
        date: formatTime(data.merging_at),
        diff: dayjs(data.finished_at).diff(data.merging_at, "second"),
        icon: "pi pi-reply",
        color: "#1B61AF",
      },
      {
        status: "Finished",
        date: formatTime(data.finished_at),
        diff: `Total cost: ${dayjs(data.finished_at).diff(
          data.pending_at,
          "second"
        )}`,
        icon: "pi pi-check",
        color: "#607D8B",
      },
    ];

    const customizedMarker = (item) => {
      return (
        <span
          className={`p-shadow-2 ${styles.customMarker}`}
          style={{ backgroundColor: item.color }}
        >
          <i className={item.icon}></i>
        </span>
      );
    };

    const customizedContent = (item) => {
      return <Card title={item.status} subTitle={`${item.diff} s`}></Card>;
    };

    return (
      <React.Fragment>
        <div className={styles.timeline}>
          <Timeline
            value={timeData}
            layout="horizontal"
            align="top"
            content={(item) => item}
            marker={customizedMarker}
            content={customizedContent}
            opposite={(item) => item.date}
          />
        </div>
        <div className="inside-table-title">
          <span>Split Jobs:</span>
        </div>
        <DataTable value={data.splitJobs} showGridlines autoLayout>
          {admin.selectedUserJobs.splitJobFields.map((item, index) => {
            if (item.dataField === "progress") {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) => progressBodyTemplate(rowData.progress)}
                  sortable
                />
              );
            }

            return (
              <Column
                key={index}
                field={item.dataField}
                header={item.displayField}
                sortable
              />
            );
          })}
        </DataTable>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Button
        icon="pi pi-briefcase"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={fetchData}
      />
      <Dialog
        header={`${rowData.account}'s Jobs`}
        visible={displayDialog}
        modal
        breakpoints={{ "1440px": "80vw", "760px": "90vw", "576px": "100vw" }}
        onHide={onHide}
      >
        <DataTable
          autoLayout
          value={Object.values(admin.selectedUserJobs.data.docs)}
          expandedRows={expandedRows}
          className="p-datatable-sm"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="_id"
          showGridlines
        >
          <Column expander style={{ width: "3em" }} />

          {admin.selectedUserJobs.fields.map((item, index) => {
            if (item.dataField === "overall_progress") {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) =>
                    progressBodyTemplate(rowData.overall_progress)
                  }
                  sortable
                />
              );
            }
            return (
              <Column
                key={index}
                header={item.displayField}
                field={item.dataField}
                sortable
                filter
                filterMatchMode="contains"
              />
            );
          })}
        </DataTable>
        <CustomPaginator
          totalRecords={admin.selectedUserJobs.data.totalDocs}
          onPageChange={({ page, limit }) =>
            dispatch(fetchUserJobs({ page, limit, accountId: rowData._id }))
          }
        />
      </Dialog>
    </div>
  );
};

export default UserJobsTable;
