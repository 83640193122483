import Cookies from "js-cookie";

const auth = JSON.parse(Cookies.get("transcoder") || "{}");

const initialState = {
  isAdmin: auth.isAdmin ? auth.isAdmin : false,
  token: auth.token,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN": {
      return { ...state, ...payload };
    }

    case "LOGOUT": {
      return { ...state, token: "", isAdmin: false };
    }

    case "SET_TOKEN": {
      return { ...state, token: payload.token };
    }

    default: {
      return state;
    }
  }
};
