import React, { useState } from "react";
import { Paginator } from "primereact/paginator";

const CustomPaginator = ({
  totalRecords,
  onPageChange,
  hasNextPage,
  limit = 10,
}) => {
  const [first, setFirst] = useState(0);

  const handlePageChange = (e) => {
    setFirst(e.first);
    onPageChange({ page: e.page + 1 });
  };

  if (!totalRecords) return null;
  return (
    <Paginator
      rows={limit}
      totalRecords={totalRecords}
      first={first}
      onPageChange={handlePageChange}
    ></Paginator>
  );
};

export default CustomPaginator;
