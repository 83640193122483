import backend from "../utilts/backendAPI";

export const fetchWorkers = (data) => async (dispatch) => {
  try {
    const response = await backend.GET("/v2/api/hello/workers", data);
    dispatch({ type: "FETCH_WORKERS", payload: response });
  } catch (error) {
    return error;
  }
};

export const fetchSplitJobsByWorkerId =
  ({ limit, page, workerId }) =>
  async (dispatch) => {
    const response = await backend.GET(
      `/v2/api/hello/workers/${workerId}/splitjobs`,
      { limit, page }
    );

    dispatch({ type: "FETCH_SPLIT_JOBS_BY_WORKER_ID", payload: response });
  };

export const resetSplitJobs = () => {
  return { type: "RESET_SPLIT_JOBS" };
};
