import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Card } from "primereact/card";
import { fetchMyStats } from "../../actions/stats";
import CustomBarChart from "../../components/CustomBarChart";
import Datepicker from "../../components/Datepicker";
import LoadingView from "../../components/LoadingView";

import styles from "./index.module.scss";

const today = dayjs().format("YYYY/MM/DD");

/* 因應後端處理時間格式的方式，前端再丟 timestamp 需要加 8 小時 */

const MyStats = () => {
  const dispatch = useDispatch();
  const { stats } = useSelector((state) => state);
  const overallSpentTime = Math.round(stats.overallSpentTime);

  useEffect(() => {
    if (!stats.data) {
      dispatch(
        fetchMyStats({
          from: dayjs(today).add(8, "hour").subtract(6, "day").valueOf(),
          to: dayjs(today).add(1, "day").valueOf(),
          interval: 60 * 60 * 24 * 1000,
        })
      );
    }
  }, []);

  const onChange = (e) => {
    const seletedDate = e.target.value;

    dispatch(
      fetchMyStats({
        from: dayjs(seletedDate).add(8, "hour").valueOf(),
        to: dayjs(seletedDate).add(8, "hour").add(7, "day").valueOf(),
        interval: 60 * 60 * 24 * 1000,
      })
    );
  };

  if (!stats.data) return <LoadingView />;

  return (
    <div className={styles.mystats}>
      <div className="p-grid p-justify-between p-align-center">
        <div className="p-col-12 p-sm-4 p-md-3">
          <Card title="Overall Spent Time">
            {overallSpentTime > 1
              ? `${overallSpentTime} secs`
              : `${overallSpentTime} sec`}
          </Card>
        </div>
        <div className="p-col-12 p-md-4">
          <Datepicker onChange={onChange} />
        </div>
      </div>
      <Card>
        <CustomBarChart title="Total Spent Time" data={stats.data} />
      </Card>
    </div>
  );
};

export default MyStats;
