import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { fetchUserStats, resetUserStats } from "../../actions/admin";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import CustomBarChart from "../../components/CustomBarChart";
import Datepicker from "../../components/Datepicker";

const today = dayjs().format("YYYY/MM/DD");

/* 因應後端處理時間格式的方式，前端再丟 timestamp 需要加 8 小時  */

const SpentTimePopup = ({ rowData }) => {
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);
  const { admin } = useSelector((state) => state);
  const overallSpentTime = Math.round(admin.selectedUserStats.overallSpentTime);

  const onHide = () => {
    setDisplayDialog(false);
    dispatch(resetUserStats());
  };

  const onOpen = () => {
    setDisplayDialog(true);
    dispatch(
      fetchUserStats({
        formData: {
          from: dayjs(today).add(8, "hour").subtract(6, "day").valueOf(),
          to: dayjs(today).add(1, "day").valueOf(),
          interval: 60 * 60 * 24 * 1000,
        },
        accountId: rowData._id,
      })
    );
  };

  const onChange = (e) => {
    const seletedDate = e.target.value;

    dispatch(
      fetchUserStats({
        formData: {
          from: dayjs(seletedDate).add(8, "hour").valueOf(),
          to: dayjs(seletedDate).add(8, "hour").add(7, "day").valueOf(),
          interval: 60 * 60 * 24 * 1000,
        },
        accountId: rowData._id,
      })
    );
  };

  return (
    <div>
      <Button
        icon="pi pi-chart-bar"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={onOpen}
      />
      <Dialog
        header="Total Spent Time"
        breakpoints={{ "1440px": "70vw", "760px": "90vw", "576px": "100vw" }}
        visible={displayDialog}
        style={{ minHeight: "90vh" }}
        modal
        onHide={onHide}
      >
        <div className="p-grid p-justify-between p-align-center">
          <div className="p-col-12 p-sm-4 p-md-3">
            <Card title="Overall Spent Time">
              {overallSpentTime > 1
                ? `${overallSpentTime} secs`
                : `${overallSpentTime} sec`}
            </Card>
          </div>
          <div className="p-col-12 p-md-4">
            <Datepicker onChange={onChange} />
          </div>
        </div>
        {admin.selectedUserStats.data ? (
          <CustomBarChart
            title="Total Spent Time"
            data={admin.selectedUserStats.data}
          />
        ) : (
          "loading"
        )}
      </Dialog>
    </div>
  );
};

export default SpentTimePopup;
