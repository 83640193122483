import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyJobs } from "../../actions/jobs";
import { TabView, TabPanel } from "primereact/tabview";
import MyJobsTable from "../MyJobsTable";

const MyJobsTabView = () => {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state);

  useEffect(() => {
    dispatch(fetchMyJobs());
  }, []);

  return (
    <div>
      <TabView>
        <TabPanel header="All" className="p-p-0">
          <MyJobsTable jobs={jobs} isOnlyShowInprogress={false} />
        </TabPanel>
        <TabPanel header="In Progress">
          <MyJobsTable jobs={jobs} isOnlyShowInprogress={true} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default MyJobsTabView;
