import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createUser } from "../../actions/users";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

const CreatUserForm = () => {
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.account) {
        errors.account = "Account is required.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      const formikData = JSON.parse(JSON.stringify(data));

      dispatch(createUser(formikData));
      setDisplayDialog(false);
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onHide = () => {
    setDisplayDialog(false);
    formik.resetForm();
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  const Footer = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
      <Button
        label="Create"
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.handleSubmit}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Button
        type="button"
        label="Create User"
        className="p-button-outlined"
        onClick={onOpen}
      />
      <Dialog
        header="Create User"
        footer={Footer}
        visible={displayDialog}
        modal
        onHide={onHide}
      >
        <form autoComplete="off" method="post" action="/form">
          <div className="p-fluid">
            <div className="p-field p-mb-6">
              <span className="p-float-label">
                <InputText
                  id="account"
                  name="account"
                  value={formik.values.account}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("account"),
                  })}
                />
                <label
                  htmlFor="account"
                  className={classNames({
                    "p-error": isFormFieldValid("account"),
                  })}
                >
                  Account
                </label>
              </span>
              {getFormErrorMessage("account")}
            </div>
            <div className="p-field p-mb-5">
              <span className="p-float-label">
                <InputText
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={true}
                  autoComplete="new-password"
                  className={classNames({
                    "p-invalid": isFormFieldValid("password"),
                  })}
                />
                <label
                  htmlFor="password"
                  className={classNames({
                    "p-error": isFormFieldValid("password"),
                  })}
                >
                  Password
                </label>
              </span>
              {getFormErrorMessage("password")}
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default CreatUserForm;
