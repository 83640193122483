import createToast from "../utilts/creatToast";

export const addToast = (options = {}) => {
  return { type: "ADD_TOAST", payload: createToast(options) };
};

export const removeToast = (id) => {
  return {
    type: "REMOVE_TOAST",
    payload: id,
  };
};
