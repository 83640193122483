import React from "react";
import { Card } from "primereact/card";
import UserListTable from "../../components/UserList";
import styles from "./index.module.scss";

const UserList = () => {
  return (
    <div className={styles.userList}>
      <Card>
        <UserListTable />
      </Card>
    </div>
  );
};

export default UserList;
