const initialState = {
  workers: {
    fields: [],
    data: null,
  },
  splitJobsOfSelectedWorker: { fields: [], data: { docs: [] } },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_WORKERS": {
      return { ...state, workers: payload };
    }

    case "FETCH_SPLIT_JOBS_BY_WORKER_ID": {
      return { ...state, splitJobsOfSelectedWorker: payload };
    }

    case "RESET_SPLIT_JOBS": {
      return {
        ...state,
        splitJobsOfSelectedWorker: { fields: [], data: { docs: [] } },
      };
    }

    default: {
      return state;
    }
  }
};
