const initialState = { fields: [], data: null, isLoading: true };

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_USERS": {
      const objData = payload.data.docs.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});

      return {
        ...payload,
        data: { ...payload.data, docs: objData },
      };
    }

    case "CREATE_USER": {
      return {
        ...state,
        data: {
          ...state.data,
          docs: { ...state.data.docs, [payload._id]: payload },
        },
      };
    }

    case "DELETE_USER": {
      const newAccounts = { ...state.data.docs };
      delete newAccounts[payload.accountId];

      return { ...state, data: { ...state.data, docs: newAccounts } };
    }

    default: {
      return state;
    }
  }
};
