import backend from "../utilts/backendAPI";
import { addToast } from "./toasts";
import { setUsersInprogressJobs } from "./admin";
import { setIsLoading } from "./loading";

export const fetchMyJobs = (data) => async (dispatch) => {
  try {
    const response = await backend.GET(`/v2/api/hello/accounts/jobs`, data);
    dispatch({ type: "FETCH_MY_JOBS", payload: response });
  } catch (error) {
    return error;
  }
};

export const createJob = (data) => async (dispatch) => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await backend.POST("/v2/api/transcode/job", data);
    dispatch(
      addToast({
        severity: "success",
        content: "Add job successfully",
        closable: false,
        life: 3000,
      })
    );
    dispatch(fetchMyJobs());
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "Error",
        closable: false,
        life: 3000,
      })
    );
  }
  dispatch(setIsLoading({ isLoading: false }));
};

export const removeMp4 =
  ({ uuid, _id }) =>
  async (dispatch) => {
    try {
      await backend.POST("/v2/api/transcode/remove_mp4", { uuid });
      dispatch(
        addToast({
          severity: "success",
          content: "Remove Mp4 successfully",
          closable: false,
          life: 3000,
        })
      );
      dispatch({ type: "REMOVE_MP4", payload: { _id } });
    } catch (error) {
      dispatch(
        addToast({
          severity: "error",
          content: "Error",
          closable: false,
          life: 3000,
        })
      );
    }
  };

export const setInprogressJobs = (data) => (dispatch, getState) => {
  const { auth } = getState();

  if (!auth.isAdmin) {
    dispatch({ type: "SET_MY_INPROGRESS_JOBS", payload: data });

    if (data.status === "finished") {
      dispatch({ type: "REMOVE_MY_INPROGRESS_JOBS", payload: data });
    }
    return;
  }

  if (auth.isAdmin) {
    if (data.account === "meshub") {
      dispatch({ type: "SET_MY_INPROGRESS_JOBS", payload: data });

      if (data.status === "finished") {
        dispatch({ type: "REMOVE_MY_INPROGRESS_JOBS", payload: data });
      }
    } else {
      dispatch(setUsersInprogressJobs(data));
    }
  }
};

export const deletePendingJobs = (data) => async (dispatch) => {
  try {
    const response = await backend.POST(
      `/v2/api/hello/accounts/jobs/cancel`,
      data
    );
    dispatch(
      addToast({
        severity: "success",
        content: `Deleted ${response.actualDeletedCounts} jobs`,
        closable: false,
        life: 3000,
      })
    );

    dispatch({
      type: "DELETE_PENDING_JOBS",
      payload: { jobIds: response.deletedJobIds },
    });
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: `Error`,
        closable: false,
        life: 3000,
      })
    );
  }
};
