import dayjs from "dayjs";

const initialState = {
  selectedUserJobs: { fields: [], splitJobFields: [], data: { docs: {} } },
  selectedUserStats: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_USER_JOBS": {
      const objData = payload.data.docs.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});

      return {
        ...state,
        selectedUserJobs: {
          ...payload,
          data: { ...payload.data, docs: objData },
        },
      };
    }

    case "RESET_USER_JOBS": {
      return {
        ...state,
        selectedUserJobs: {
          fields: [],
          splitJobFields: [],
          data: { docs: {} },
        },
      };
    }

    case "SET_USERS_INPROGRESS_JOBS": {
      return {
        ...state,
        selectedUserJobs: {
          ...state.selectedUserJobs,
          data: {
            ...state.selectedUserJobs.data,
            docs: {
              ...state.selectedUserJobs.data.docs,
              [payload._id]: payload,
            },
          },
        },
      };
    }

    case "FETCH_USER_STATS": {
      const formatedData = payload.data.map((item) => ({
        ...item,
        totalSpentTime: Math.round(item.totalSpentTime),
        timestamp: dayjs(item.timestamp).format("MM/DD"),
      }));

      return {
        ...state,
        selectedUserStats: { ...payload, data: formatedData },
      };
    }

    case "RESET_USER_STATS": {
      return {
        ...state,
        selectedUserStats: {},
      };
    }

    default: {
      return state;
    }
  }
};
