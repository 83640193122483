import React, { useState } from "react";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

const monthNavigatorTemplate = (e) => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      style={{ lineHeight: 1 }}
    />
  );
};

const yearNavigatorTemplate = (e) => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      className="p-ml-2"
      style={{ lineHeight: 1 }}
    />
  );
};

const Datepicker = ({ onChange }) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (e) => {
    onChange(e);
    setDate(e.target.value);
  };

  return (
    <Calendar
      id="icon"
      value={date}
      dateFormat="yy/mm/dd"
      monthNavigator
      yearNavigator
      yearRange="1930:2030"
      maxDate={new Date()}
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
      showIcon
      onChange={handleChange}
    />
  );
};

export default Datepicker;
