export default [
  {
    name: "My Jobs",
    path: "/",
    icon: "pi pi-table",
    adminOnly: false,
  },
  {
    name: "Analytics",
    path: "/analytics",
    icon: "pi pi-chart-bar",
    adminOnly: false,
  },
  {
    name: "Workers",
    path: "/workers",
    icon: "pi pi-sitemap",
    adminOnly: true,
  },
  {
    name: "Users",
    path: "/users",
    icon: "pi pi-users",
    adminOnly: true,
  },
];
