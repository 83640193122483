const options = {
  numberOptions: [
    {
      label: "1",
      meshubNumbers: 1,
    },
    {
      label: "2",
      meshubNumbers: 2,
    },
    {
      label: "3",
      meshubNumbers: 3,
    },
    {
      label: "4",
      meshubNumbers: 4,
    },
    {
      label: "5",
      meshubNumbers: 5,
    },
    {
      label: "6",
      meshubNumbers: 6,
    },
    {
      label: "7",
      meshubNumbers: 7,
    },
    {
      label: "8",
      meshubNumbers: 8,
    },
  ],
  bitrateOptions: [
    {
      label: "1 Mbps",
      paramBitrate: "1000000",
    },
    {
      label: "5 Mbps",
      paramBitrate: "5000000",
    },
    {
      label: "10 Mbps",
      paramBitrate: "10000000",
    },
  ],
  presetOptions: [
    {
      label: "ultrafast",
      paramPreset: "ultrafast",
    },
    {
      label: "fast",
      paramPreset: "fast",
    },
    {
      label: "medium",
      paramPreset: "medium",
    },
  ],
  crfOptions: [
    { label: "23", paramCrf: 23 },
    { label: "40", paramCrf: 40 },
    { label: "51", paramCrf: 51 },
  ],
  resolutionOptions: [
    {
      label: "1080 P",
      resolution: { paramResolutionWidth: 1920, paramResolutionHeight: 1080 },
    },
    {
      label: "720 P",
      resolution: { paramResolutionWidth: 1280, paramResolutionHeight: 720 },
    },
    {
      label: "360 P",
      resolution: { paramResolutionWidth: 640, paramResolutionHeight: 360 },
    },
  ],
};

export default options;
