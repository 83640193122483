import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSplitJobsByWorkerId,
  resetSplitJobs,
} from "../../actions/workers";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import CustomProgressBar from "../CustomProgressBar";
import CustomPaginator from "../CustomPaginator";

const SplitJobsTable = ({ rowData }) => {
  const dispatch = useDispatch();
  const { workers } = useSelector((state) => state);
  const [displayDialog, setDisplayDialog] = useState(false);

  const onHide = () => {
    setDisplayDialog(false);
    dispatch(resetSplitJobs());
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  const fetchData = () => {
    dispatch(fetchSplitJobsByWorkerId({ workerId: rowData._id }));
    onOpen();
  };

  const progressBodyTemplate = (value) => {
    return <CustomProgressBar value={value} />;
  };

  return (
    <div>
      <Button
        icon="pi pi-sitemap"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={fetchData}
      />
      <Dialog
        header={`Split Jobs`}
        visible={displayDialog}
        modal
        breakpoints={{ "1440px": "70vw", "760px": "90vw", "576px": "100vw" }}
        onHide={onHide}
      >
        <DataTable
          autoLayout
          value={workers.splitJobsOfSelectedWorker.data.docs}
          className="p-datatable-sm"
          dataKey="_id"
          showGridlines
        >
          {workers.splitJobsOfSelectedWorker.fields.map((item, index) => {
            if (item.dataField === "progress") {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) => progressBodyTemplate(rowData.progress)}
                  sortable
                />
              );
            }

            return (
              <Column
                key={index}
                header={item.displayField}
                field={item.dataField}
                sortable
                filter
                filterMatchMode="contains"
              />
            );
          })}
        </DataTable>
        <CustomPaginator
          totalRecords={workers.splitJobsOfSelectedWorker.data.totalDocs}
          onPageChange={({ page, limit }) =>
            dispatch(
              fetchSplitJobsByWorkerId({
                page,
                limit,
                workerId: rowData._id,
              })
            )
          }
        />
      </Dialog>
    </div>
  );
};

export default SplitJobsTable;
